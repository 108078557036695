<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <v-tooltip
          color='#212121'
          top

        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              @click="$emit('new')"
              color='primary'
              class='me-2'
              v-bind='attrs'
              icon
              v-on='on'
            >
              <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('back') }}</span>
        </v-tooltip>{{ $t('Receipt Summary') }} ({{ $t('oldFormat') }})
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='d-none d-md-block'
          :loading='exportLoading'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>

        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters :default-status='2' @onSendDate='addPayload'></DateFilters>
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model.trim='selectTeam'
            :items='teamsAndUserList'
            :label="$t('team_select')"
            item-text='teams_name'
            item-value='teams_id'
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model.trim='taxSelect'
            :items='taxList'
            :label="$t('tax_type')"
            :item-text='$i18n.locale'
            item-value='value'
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model.trim='pointSelect'
            :items='pointList'
            :label="$t('point')"
            :item-text='$i18n.locale'
            item-value='value'
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-text-field
            v-model.trim='searchtext'
            dense
            outlined
            :label="`${$t('search')} ${$t('pressEnterForSearch')}`"
            @keypress.enter='addPayload(payload.start, payload.end)'
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id`]='{ item }'>
          <router-link
            v-if='item.order_id'
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.order_create`]='{ item }'>
          {{ item.order_create | sumdatetime }}
        </template>
        <template v-slot:[`item.discount`]='{ item }'>
          <span v-if='!item.orderpay_all' class='primary--text font-weight-bold'>
            {{ item.discount }}
          </span>
          <span v-else-if="item.discount == $t('total')" class='success--text font-weight-bold'>
            {{ item.discount }}
          </span>
          <span v-else-if="item.discount == $t('accrued_account')"
                class='error--text font-weight-bold'>
            {{ item.discount }}
          </span>
          <span v-else-if="item.discount == $t('deposit')" class='warning--text font-weight-bold'>
            {{ item.discount }}
          </span>
          <span v-else-if="item.discount == $t('wait_for_payment')"
                class='error--text font-weight-bold'>
            {{ item.discount }}
          </span>
          <span v-else-if="item.discount == $t('total_amount')"
                class='primary--text font-weight-bold'>
            {{ item.discount }}
          </span>
          <span v-else class='set-font-table-td'>
            {{ item.discount }}
          </span>
        </template>
        <template v-slot:[`item.orderpay_all`]='{ item }'>
          <span v-if='!item.orderpay_all' class='primary--text font-weight-bold'>
            {{ item.orderpay_all }}
          </span>
          <span v-else-if="item.discount == $t('total')" class='success--text font-weight-bold'>
            {{ item.orderpay_all }}
          </span>
          <span v-else-if="item.discount == $t('accrued_account')"
                class='error--text font-weight-bold'>
            {{ item.orderpay_all }}
          </span>
          <span v-else-if="item.discount == $t('deposit')" class='warning--text font-weight-bold'>
            {{ item.orderpay_all }}
          </span>
          <span v-else-if="item.discount == $t('wait_for_payment')"
                class='error--text font-weight-bold'>
            {{ item.orderpay_all }}
          </span>
          <span v-else-if="item.discount == $t('total_amount')"
                class='primary--text font-weight-bold'>
            {{ item.orderpay_all }}
          </span>
          <span v-else class='set-font-table-td'>
            {{ item.orderpay_all }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline, mdiArrowLeftBold } from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import Comeback from '../Comeback.vue'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { reportSummary, removeComma } from '../useExcel'
import { sumdate, sumdateEn, sumdatetime } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import teamReport from '@/api/teamReport'
import vatDataForSearch from '@/@fake-db/data/vatDataForSearch.json'
import pointTypeList from '@/@fake-db/data/pointTypeList.json'

export default {
  filters: {
    sumdatetime,
  },
  components: {
    Comeback,
    DateFilters,
    ConverseDate,
  },
  setup() {
    const XLSX = require('xlsx')
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      {
        text: i18n.t('receipt_number'),
        value: 'order_id',
        width: 120,
      },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 100 },
      { text: i18n.t('customer'), value: 'customer_name', width: 180 },
      { text: i18n.t('group_customers'), value: 'customer_group_name', width: 110 },
      { text: i18n.t('staff_team'), value: 'user_fullname', width: 300 },
      { text: i18n.t('list'), value: 'detail', width: 400 },
      { text: i18n.t('date_of_receipt'), value: 'order_create', width: 180 },
      { text: i18n.t('payment_channel'), value: 'account', width: 120 },
      {
        text: i18n.t('point'),
        value: 'point',
        width: 100,
        align: 'end',
      },
      {
        text: i18n.t('net_amount'),
        value: 'order_total',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('discount'),
        value: 'discount',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('total_payment'),
        value: 'orderpay_all',
        width: 210,
        align: 'end',
      },
      { text: i18n.t('note'), value: 'order_comment', width: 200 },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(true)
    const header = ref('')
    const payload = ref({})
    const selectTeam = ref('')
    const taxSelect = ref('')
    const searchtext = ref('')
    const pointSelect = ref('')
    const teamsAndUserList = ref([])
    const taxList = ref(vatDataForSearch.data)
    const pointList = ref(pointTypeList.data)
    const exportLoading = ref(false)
    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        teams_id: selectTeam.value,
        order_tax_id: taxSelect.value,
        order_point_id: pointSelect.value,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    teamReport.teamReportList().then(res => {
      teamsAndUserList.value = res
      teamsAndUserList.value.unshift({
        teams_id: '',
        teams_name: i18n.t('all'),
      })
    })
    const serachReport = async payload => {
      loading.value = true
      const { start, end } = payload
      const data = await reportSummary(payload)
      dataTableList.value = data
      header.value = `${i18n.t('report_receipt_summary')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)}`
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    watch([taxSelect, pointSelect, selectTeam], () => {
      addPayload(payload.value.start, payload.value.end)
    })

    const exportExcel = async () => {
      exportLoading.value = true
      const myData = await removeComma(JSON.parse(JSON.stringify(dataTableList.value)))
      let dataExport = myData.map(item => {
        item.detail = `${item.detail ? item.detail : ''}`
        if (item.order_create) {
          // item.order_create = sumdatetime(item.order_create)
        }
        item.account = `${item.account ? item.account : ''}`
        item.customer_tags = `${item.customer_tags ? item.customer_tags : ''}`
        delete item.order_id_pri

        return item
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))
      const fileName = `${header.value}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${header.value}`],
        [
          '#',
          i18n.t('receipt_number'),
          i18n.t('customer_code'),
          i18n.t('customer'),
          i18n.t('group_customers'),
          i18n.t('staff'),
          i18n.t('list'),
          i18n.t('date_of_receipt'),
          i18n.t('payment_channel'),
          i18n.t('point'),
          i18n.t('net_amount'),
          i18n.t('discount'),
          i18n.t('total_payment'),
          i18n.t('tag'),
          i18n.t('note'),
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'order_id',
          'customer_id',
          'customer_name',
          'customer_group_name',
          'user_fullname',
          'detail',
          'order_create',
          'account',
          'point',
          'order_total',
          'discount',
          'orderpay_all',
          'customer_tags',
          'order_comment',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 13 } }]
      const wscols = [
        { wch: 6 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 25 },
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 40 },
        { wch: 40 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, i18n.t('report_receipt_summary'))

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      XLSX,
      columns,
      dataTableList,
      options,
      loading,
      header,
      teamsAndUserList,
      selectTeam,
      taxList,
      taxSelect,
      pointSelect,
      searchtext,
      pointList,
      exportLoading,
      payload,
      addPayload,
      exportExcel,
      serachReport,
      icons: {
        mdiArrowLeftBold,
      },
      mdiFileExcelOutline,
    }
  },
}
</script>
