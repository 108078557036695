<template>
  <div>
    <ReportSummaryNew v-if="status === 'new'" @old="status = 'old'" />
    <ReportSummaryOld v-else  @new="status = 'new'"/>
  </div>
</template>
<script>
import ReportSummaryNew from '@/views/reports/block1/ReportSummaryNew.vue'
import ReportSummaryOld from '@/views/reports/block1/ReportSummaryOld.vue'
import { ref } from '@vue/composition-api'

export default {
  components: { ReportSummaryNew, ReportSummaryOld },
  setup() {
    const status = ref('new')
    return {
      status,
    }
  },
}

</script>
